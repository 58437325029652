import {
  doc,
  query,
  where,
  addDoc,
  getDocs,
  updateDoc,
  collection,
  getCountFromServer,
} from 'firebase/firestore';
import { DB } from '../config';
import { WaitlistEntry } from 'types/waitlist';

export const user = {
  async updateReferrerCount(
    waitlistRef: any,
    referralId: string
  ): Promise<void> {
    const referrerQuery = query(
      waitlistRef,
      where('referralId', '==', referralId)
    );
    const snapshot = await getDocs(referrerQuery);

    if (!snapshot.empty) {
      const referrerDoc = snapshot.docs[0];
      const referrerData = referrerDoc.data() as { referedCount?: number };
      await updateDoc(doc(waitlistRef, referrerDoc.id), {
        referedCount: (referrerData.referedCount || 0) + 1,
      });
    }
  },

  submitWaitlistEntry: async (
    accountId: string,
    applicationId: string,
    waitlistEntry: any
  ) => {
    try {
      const waitlistRef = collection(
        DB,
        'accounts',
        accountId,
        'applications',
        applicationId,
        'waitlist'
      );

      const docRef = await addDoc(waitlistRef, waitlistEntry);

      if (waitlistEntry.referredBy) {
        await user.updateReferrerCount(waitlistRef, waitlistEntry.referredBy);
      }

      return { success: true, id: docRef.id };
    } catch (error) {
      console.error('Error submitting waitlist entry:', error);
      throw error;
    }
  },
  getWaitlistEntries: async (accountId: string, applicationId: string) => {
    try {
      const waitlistRef = collection(
        DB,
        'accounts',
        accountId,
        'applications',
        applicationId,
        'waitlist'
      );

      const snapshot = await getDocs(waitlistRef);
      return snapshot.docs.map((doc) => doc.data() as WaitlistEntry);
    } catch (error) {
      console.error('Error getting waitlist entries:', error);
      throw error;
    }
  },
  getWaitlistCount: async (accountId: string, applicationId: string) => {
    try {
      const waitlistRef = collection(
        DB,
        'accounts',
        accountId,
        'applications',
        applicationId,
        'waitlist'
      );

      const snapshot = await getCountFromServer(waitlistRef);
      return snapshot.data().count;
    } catch (error) {
      console.error('Error getting waitlist count:', error);
      throw error;
    }
  },
  getWaitlistEntry: async (
    accountId: string,
    applicationId: string,
    position: number
  ) => {
    try {
      const waitlistRef = collection(
        DB,
        'accounts',
        accountId,
        'applications',
        applicationId,
        'waitlist'
      );
      const snapshot = await getDocs(waitlistRef);
      const entry = snapshot.docs.find(
        (doc) => doc.data().position === position
      );
      return entry ? entry.data() : null;
    } catch (error) {
      console.error('Error getting waitlist entry:', error);
      throw error;
    }
  },
  getWaitlistEntryByEmail: async (
    accountId: string,
    applicationId: string,
    email: string
  ) => {
    try {
      const waitlistRef = collection(
        DB,
        'accounts',
        accountId,
        'applications',
        applicationId,
        'waitlist'
      );

      const q = query(waitlistRef, where('email', '==', email));

      const snapshot = await getDocs(q);
      if (snapshot.size > 0) return snapshot.docs[0].data() as WaitlistEntry;
      return null;
    } catch (error) {
      console.error('Error getting waitlist entry:', error);
      throw error;
    }
  },
};
