import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import UserData from './pages/userData';
import Admin from './pages/Admin';
import About from './pages/About';
import { DEFAULT_APPLICATION_ID } from './config-global';
import { HelmetProvider } from 'react-helmet-async';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'firebase-lib/config';

const RedirectComponent: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/waitlist/${DEFAULT_APPLICATION_ID}`);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-12 h-12 rounded-full border-4 border-purple-500 border-t-transparent animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium">Loading...</p>
    </div>
  );
};

const App: React.FC = () => {
  useEffect(() => {
    logEvent(analytics, 'app_initialized');
  }, []);

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<RedirectComponent />} />
        <Route path="/waitlist/:id" element={<UserData />} />
        <Route path="/admin/:id" element={<Admin />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </HelmetProvider>
  );
};

export default App;
