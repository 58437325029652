import { useParams } from 'react-router-dom';
import Layout from '../components/layout';
import { ReactComponent as ShareIcon } from 'assets/svg/share.svg';
import { ReactComponent as CopyIcon } from 'assets/svg/copy.svg';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

type SuccessProps = {
  referralId: string;
  email: string;
  position: number;
};

const Success: React.FC<SuccessProps> = ({
  referralId = '',
  email = '',
  position,
}) => {
  const { id } = useParams();
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const getReferralUrl = () => {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}/waitlist/${id}?ref=${referralId}`;
  };

  const shareUrl = async () => {
    try {
      await navigator.share({
        text: `I'm on Polaam, the Zero-Commission Rideshare platform. Join now and make 100% on each ride! ${getReferralUrl()}`,
      });
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(getReferralUrl());
      setShowCopyTooltip(true);
      setTimeout(() => setShowCopyTooltip(false), 2000);
    } catch (err) {
      console.error('Error copying to clipboard:', err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Polaam | Confirmation</title>
        <meta name="description" content="You're on the Polaam Waitlist" />
      </Helmet>
      <Layout width="w-full" showBottomSvg={true}>
        <div className="text-center">
          <h1 className="xl:text-[40px] text-[32px] font-semibold leading-[120%] mb-4">
            🎉 Awesome! 👏
            <br />
            You&apos;re <span className="text-emerald-500">#{position}</span> on
            the Polaam Waitlist.
          </h1>
          {email && (
            <p className="text-gray-600 mb-8">
              This reservation is held for <b>{email}</b>.
            </p>
          )}
          <div className="mb-8">
            <p className="mt-4 text-xl">
              Earn $15 in-app for every driver you refer. No limit! Share your
              unique link below.
            </p>
          </div>
          <div className="bg-gray-50 mb-6 border border-gray-200 p-3 rounded-xl flex items-center gap-2 max-w-md mx-auto">
            <input
              type="text"
              value={getReferralUrl()}
              readOnly
              className="flex-1 bg-transparent text-sm"
            />
            <div className="relative">
              <button
                onClick={copyToClipboard}
                className="bg-emerald-500 text-white p-2 rounded-lg"
              >
                <CopyIcon className="w-4 h-4" />
              </button>
              {showCopyTooltip && (
                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded whitespace-nowrap">
                  Copied!
                </div>
              )}
            </div>
            <button
              onClick={shareUrl}
              className="bg-emerald-500 text-white p-2 rounded-lg"
            >
              <ShareIcon className="w-4 h-4" />
            </button>
          </div>
          <a href="/about" className="text-emerald-500 hover:underline">
            Learn more
          </a>
        </div>
      </Layout>
    </>
  );
};

export default Success;
