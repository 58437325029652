import React from 'react';
import { ReactComponent as CenterBackground } from 'assets/svg/center-background.svg';
import Car from 'assets/svg/car.svg';
import { ReactComponent as PolaamLogo } from 'assets/svg/icon.svg';

interface LayoutProps {
  children: React.ReactNode;
  width?: string;
  showBottomSvg?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  width = 'max-w-md',
  showBottomSvg = true,
}) => {
  const CONTEXT_HEIGHT = 700;
  const CAR_IMAGE_RATIO = 236 / 1440;
  const freeSpace_height = window.innerHeight - CONTEXT_HEIGHT;
  const maxHeight = Math.max(
    window.innerWidth * CAR_IMAGE_RATIO,
    window.innerHeight / 5
  );

  const showCar = freeSpace_height / window.innerWidth >= CAR_IMAGE_RATIO;

  return (
    <div className="flex items-center justify-center h-dvh">
      <div className="fixed inset-0 overflow-hidden h-dvh bg-gray-100">
        <CenterBackground className="w-full h-full" />
      </div>
      <div className="w-full h-dvh flex flex-col relative">
        <div className="relative z-10 flex justify-center pt-8 lg:pt-12">
          <PolaamLogo className="w-auto h-auto" />
        </div>

        <main className="relative z-10 flex-grow flex items-center justify-center py-2 md:py-0 sm:py-0">
          <div className={`${width} w-full px-4 sm:px-6 lg:px-8 mx-auto`}>
            {children}
          </div>
        </main>

        {showBottomSvg && showCar && (
          <div
            className="relative z-10 w-full mt-auto"
            style={{
              height: freeSpace_height,
              maxHeight: `${maxHeight}px`,
              backgroundPositionX: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: `url(${Car})`,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Layout;
