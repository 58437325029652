import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../components/layout';
import { db } from '../firebase-lib/firestore';
import { WaitlistEntry } from '../types/waitlist';
import { DEFAULT_ACCOUNT_ID } from '../config-global';
import * as _ from 'lodash';
import { ReactComponent as RefreshIcon } from 'assets/svg/refresh.svg';

const Admin: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState('');
  const [correctPasscode, setCorrectPasscode] = useState('');
  const [entries, setEntries] = useState<WaitlistEntry[]>([]);
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());

  const { id: applicationId } = useParams();

  useEffect(() => {
    if (!applicationId) return;
    const fetchWaitlistEntries = async () => {
      const entries = await db.getWaitlistEntries(
        DEFAULT_ACCOUNT_ID,
        applicationId
      );
      setEntries(_.orderBy(entries, (e) => e.position, 'desc'));
    };
    fetchWaitlistEntries();
  }, [applicationId, lastUpdateTime]);

  useEffect(() => {
    const fetchPasscode = async () => {
      const application = await db.application(
        DEFAULT_ACCOUNT_ID,
        applicationId || ''
      );

      if (application) setCorrectPasscode(application.passcode);
    };
    fetchPasscode();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (passcode === correctPasscode) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Incorrect passcode');
    }
  };

  if (!isAuthenticated) {
    return (
      <Layout>
        <h1 className="text-2xl font-bold text-center mb-6">
          Admin Authentication
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <input
              id="passcode"
              type="password"
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              placeholder="Enter passcode"
              className="input-primary"
            />
            {error && (
              <div className="text-red-500 text-sm mt-1">
                <span role="alert">{error}</span>
              </div>
            )}
          </div>
          <button type="submit" className="btn-primary">
            Access Admin Panel
          </button>
        </form>
      </Layout>
    );
  }

  return (
    <Layout width="max-w-8xl" showBottomSvg={false}>
      <div className="flex gap-4 items-start mb-6">
        <h1 className="text-xl md:text-2xl font-bold">Waitlist Management</h1>
        <button
          title={`last update: ${lastUpdateTime}`}
          onClick={() => setLastUpdateTime(new Date())}
          className="bg-emerald-500 text-white p-2 rounded-lg"
        >
          <RefreshIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Position
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Joined Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Referral Info
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Device Info
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {entries.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    #{item.position}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {new Date(
                      (item.joinedTime as any).seconds * 1000
                    ).toLocaleString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    {item.mobileNumber}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{item.email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {item.city}, {item.country}
                  </div>
                  <div className="text-sm text-gray-500">{item.zipcode}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    ID: {item.referralId}
                  </div>
                  <div className="text-sm text-gray-500">
                    Referred by: {item?.referredBy || 'N/A'}
                  </div>
                  <div className="text-sm text-gray-500">
                    Referral count: {item?.referedCount || 'N/A'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {item.browser} {item.browserVersion}
                  </div>
                  <div className="text-sm text-gray-500">
                    {item.os} {item.osVersion}
                  </div>
                  <div className="text-sm text-gray-500">{item.deviceType}</div>
                  <div className="text-sm text-gray-500">
                    {item.deviceModel &&
                      `${item.deviceVendor}: ${item.deviceModel}`}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Admin;
