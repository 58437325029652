import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet-async';

const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About | Polaam</title>
        <meta name="description" content="Learn about Polaam" />
      </Helmet>
      <Layout width="max-w-4xl">
        <div className="px-4 py-8">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-12">
            WE ARE TAKING WHAT&apos;S WRONG WITH RIDESHARE AND MAKING IT RIGHT
          </h1>

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">What we believe in</h2>
            <p className="text-gray-700 leading-relaxed">
              We started Polaam (meaning: Let&apos;s Go! or Let&apos;s Roll!) to
              give drivers an option that wasn&apos;t eating away 40-50% of
              their fares. An affordable, transparent and premium service that
              we&apos;d actually want to use ourselves. That means ditching
              things like crazy commissions, unsupportive companies and overall
              nonsense. So what about riders? Oh, yes! Riders get great service
              with happy drivers. It&apos;s that simple! Polaam!
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6">How it works</h2>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">For Drivers</h3>
              <ul className="list-decimal pl-6 space-y-3 text-gray-700">
                <li>
                  Sign up with your license, car, commercial insurance, banking
                  details, legal stuff, verification hoops, etc.
                </li>
                <li>
                  Do unlimited rides for $15 per day (We credit back $3 per
                  ride, if you do less than 5 rides. Ex: If you did only 1 ride,
                  we credit you back $12 for future use)
                </li>
                <li>
                  Get ride requests from riders. No Surge or bonus gimmicks.
                </li>
                <li>Choose your ride and accept</li>
                <li>
                  See what the rider pays, and receive 100% of the payment from
                  the rider
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">For Riders</h3>
              <ul className="list-decimal pl-6 space-y-3 text-gray-700">
                <li>Book a ride</li>
                <li>Ride safely to your destination</li>
                <li>
                  100% Payment plus tip credited to the driver (minus card/bank
                  transaction fee)
                </li>
              </ul>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6">How Referral works</h2>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">Refer a Driver</h3>
              <ul className="list-decimal pl-6 space-y-3 text-gray-700">
                <li>A driver friend uses your referral link to signup</li>
                <li>They drive their first day on Polaam</li>
                <li>
                  You receive $15 on the app for each driver referral within 15
                  days. And yes, no limits on the number of referrals. No other
                  B*llsh*t catch!
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-4">
                Refer Family or Friend Riders
              </h3>
              <ul className="list-decimal pl-6 space-y-3 text-gray-700">
                <li>You receive 3 family and friend rider referrals</li>
                <li>
                  They signup and start receiving 15% off on rides for lifetime.
                  Yes, lifetime! No catch, and no gimmicks.
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default About;
