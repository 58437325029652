// ----------------------------------------------------------------------
const isPolaam =
  window.location.origin.toLowerCase().indexOf('polaam.com') >= 0;

const DEFAULT_DEV_ACCOUNT_ID = 'NNWvT4oi7QKtglX7Bt1E';
const DEFAULT_DEV_APPLICATION_ID = '50XKc80kBdlpOEZY6vJe';

const DEFAULT_POLAAM_ACCOUNT_ID = '3gZPYFO4tbE6j3ib7NL6';
const DEFAULT_POLAAM_APPLICATION_ID = 'polaam';

const FIREBASE_DEV_CONFIG = {
  apiKey: 'AIzaSyCqoG5rpuKJBvCcrHECVm10NhZWa7qJUZw',
  authDomain: 'revate-waitlist.firebaseapp.com',
  projectId: 'revate-waitlist',
  storageBucket: 'revate-waitlist.firebasestorage.app',
  messagingSenderId: '463380208827',
  appId: '1:463380208827:web:aad26afb9dd73a59e09d9e',
  measurementId: 'G-XG6JFXBJKP',
};

const FIREBASE_POLAAM_CONFIG = {
  apiKey: 'AIzaSyCRbK-xG3w-nkcTPpGwJtfElG39p2I9OEw',
  authDomain: 'polaam.firebaseapp.com',
  projectId: 'polaam',
  storageBucket: 'polaam.firebasestorage.app',
  messagingSenderId: '356620428516',
  appId: '1:356620428516:web:fc7ee969b05d9bf53538f1',
  measurementId: 'G-095H3FZHSB',
};

export const FIREBASE_API = isPolaam
  ? FIREBASE_POLAAM_CONFIG
  : FIREBASE_DEV_CONFIG;
export const DEFAULT_ACCOUNT_ID = isPolaam
  ? DEFAULT_POLAAM_ACCOUNT_ID
  : DEFAULT_DEV_ACCOUNT_ID;
export const DEFAULT_APPLICATION_ID = isPolaam
  ? DEFAULT_POLAAM_APPLICATION_ID
  : DEFAULT_DEV_APPLICATION_ID;
