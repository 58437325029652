import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase-lib/firestore';
import { FormData } from '../types/waitlist';
import Layout from '../components/layout';
import { DEFAULT_ACCOUNT_ID } from '../config-global';
import Success from './Success';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'firebase-lib/config';

const UserData: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [referralId, setReferralId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [newPositionNumber, setNewPositionNumber] = useState(0);
  const [entryCount, setEntryCount] = useState(0);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    db.getWaitlistCount(DEFAULT_ACCOUNT_ID, id || '').then(setEntryCount);
  }, []);

  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (formData: FormData) => {
    setIsSubmitting(true);
    try {
      const existingEntry = await db.getWaitlistEntryByEmail(
        DEFAULT_ACCOUNT_ID,
        id || '',
        formData.email.toLowerCase()
      );

      if (existingEntry) {
        setReferralId(existingEntry.referralId);
        setIsSuccess(true);
        setEmail(formData.email);
        setNewPositionNumber(existingEntry.position);
        logEvent(analytics, 're_submit', {
          accountId: DEFAULT_ACCOUNT_ID,
          applicationId: id,
          email: formData.email,
        });
        return;
      }

      // Get referral from URL search params
      const searchParams = new URLSearchParams(location.search);
      const referralCode = searchParams.get('ref');

      // Get user's geolocation
      const geoResponse = await fetch('https://ipapi.co/json/');
      const geoData = await geoResponse.json();

      // Get current position from Firebase
      const currentPosition = await db.getWaitlistCount(
        DEFAULT_ACCOUNT_ID,
        id || ''
      );

      const userAgent = UAParser();

      const waitlistEntry = {
        id: uuidv4(),
        referralId: generateReferralId(),
        position: currentPosition + 1,
        ...formData,
        email: formData.email.toLowerCase(),
        referedCount: 0,
        ...(referralCode && { referredBy: referralCode }),
        joinedTime: new Date(),
        os: userAgent.os.name || 'Unknown',
        osVersion: userAgent.os.version || 'Unknown',
        browser: userAgent.browser.name || 'Unknown',
        browserVersion: userAgent.browser.version || 'Unknown',
        deviceType: userAgent.device.type || 'desktop',
        deviceModel: userAgent.device.model || 'Unknown',
        deviceVendor: userAgent.device.vendor || 'Unknown',
        ip: geoData.ip,
        region: geoData.region,
        city: geoData.city,
        state: geoData.region_code,
        country: geoData.country_name,
        zipcode: geoData.postal,
        lat: geoData.latitude,
        lng: geoData.longitude,
        emailSent: false,
      };

      await db.submitWaitlistEntry(DEFAULT_ACCOUNT_ID, id || '', waitlistEntry);
      setReferralId(waitlistEntry.referralId);
      setIsSuccess(true);
      setEmail(formData.email);
      setNewPositionNumber(waitlistEntry.position);
      logEvent(analytics, 'success_submit', {
        accountId: DEFAULT_ACCOUNT_ID,
        applicationId: id,
        email: formData.email,
      });
    } catch (error) {
      console.error('Error:', error);
      logEvent(analytics, 'failure', {
        accountId: DEFAULT_ACCOUNT_ID,
        applicationId: id,
        email: formData.email,
        error,
      });
      alert('Error submitting data. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const generateReferralId = () => {
    return Math.random().toString(36).substring(2, 8);
  };

  if (isSuccess) {
    return (
      <Success
        referralId={referralId}
        email={email}
        position={newPositionNumber}
      />
    );
  }

  return (
    <Layout width="max-w-2xl">
      <div className="w-full mx-auto text-center">
        <h3 className="lg:text-[40px] text-4xl font-bold mb-4 px-4">
          Say goodbye to commissions
        </h3>
        <p className="text-base sm:text-lg mb-8 text-gray-800 px-4">
          Drive with Polaam. Make 100% on each ride!{' '}
          <a href="/about" className="text-emerald-500 hover:underline">
            Learn more
          </a>
        </p>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 sm:space-y-5 max-w-[440px] mx-auto"
        >
          <div>
            <input
              {...register('name', {
                required: 'Full name is required',
                pattern: {
                  value: /^[A-Za-z]+\s+[A-Za-z]+/,
                  message: 'Please enter your full name (first and last name)',
                },
              })}
              type="text"
              className="input-primary"
              placeholder="Full Name"
            />
            {errors.name && (
              <p className="mt-2 text-sm text-red-600 text-start">
                {errors.name.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Invalid email address',
                },
              })}
              type="email"
              className="input-primary"
              placeholder="Email"
            />
            {errors.email && (
              <p className="mt-2 text-sm text-red-600 text-start">
                {errors.email.message}
              </p>
            )}
          </div>

          <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
              +1
            </span>
            <input
              {...register('mobileNumber')}
              type="tel"
              className="input-primary pl-12"
              placeholder="Mobile Number"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="btn-primary text-xl"
          >
            {isSubmitting ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Submitting...
              </span>
            ) : (
              'Join Waitlist'
            )}
          </button>
        </form>

        {entryCount > 0 && (
          <p className="mt-4 text-emerald-500 font-medium text-sm sm:text-base">
            {entryCount} DRIVERS ALREADY JOINED!
          </p>
        )}
      </div>
    </Layout>
  );
};

export default UserData;
